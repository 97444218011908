import {
    ChangeDetectorRef,
    Component,
    OnInit, QueryList, ViewChild, ViewChildren,
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Meta} from "@angular/platform-browser";

import {PinView, PinWidget, HistoryItem, User, PinModal} from "ng-core-components";

import {environment} from "../../../environments/environment";

import {AuthService} from "../../services/auth.service";
import {ViewService} from "../../services/view.service";
import {UserService} from "../../services/user.service";

import {Widget} from "../../models/view";
import {PinIconSet} from "../../shared/iconSet";

export type widget_display_states = 'loading' | 'visible' | 'not-found' | 'expired';

const homepagePropertyPage = 15;
const registrationPropertyPage = 16;
const homepagePropertyPageList = 13;
const registrationPropertyPageList = 14;
const signinPropertyPageList = 15;

@Component({
    selector: 'pin-widget-show',
    exportAs: 'pinWidgetShow',
    templateUrl: './widget-show.html',
    styleUrls: ['./widget-show.scss'],
    host: {
        '[class]': '"pin-widget-" + widgetId',

    },
})
export class PinWidgetShow implements OnInit {
    @ViewChildren(PinWidget) _widget: QueryList<PinWidget>;
    @ViewChildren(PinView) _views: QueryList<PinView>;
    iconSet = PinIconSet;
    env = environment;

    widgetId: number;
    widget: Widget;

    state = 'hide';

    displayState: widget_display_states = 'loading';

    registrationData: any;
    signinData: any;

    menuState = false;
    backButtonVisible = false;

    user: User;
    userHistory: HistoryItem[];

    signUpVisible = false;
    signInVisible = false;

    regLoading = false;

    @ViewChild('signUp', {static: true}) _signUpView: PinView;
    @ViewChild('signIn', {static: true}) _signInView: PinView;


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private viewService: ViewService,
        private userService: UserService,
        private ref: ChangeDetectorRef,
        private meta: Meta,
        public modal: PinModal) {
    }

    ngOnInit() {
        if (this.userService.getUser()) {
            this.user = this.userService.getUser();
        }
        this.userService.userObservable.subscribe(
            (user: User) => {
                this.user = user;
            }
        );

        this.authService.tokenRefresh.subscribe((data: any) => {
            this.getContent();
            this.ref.markForCheck();
        });



        /**
         * Grab the widget ID from the URL,
         * return the widget from View Service
         */
        this.route.paramMap.subscribe((params: any) => {

            this.widgetId = parseInt(params.get('id'));
            this.getContent();

        });

        /** */
        this.initiateRegistration();

        this.viewService.subscribeToWidgetCompletes();

    }

    /**
     *
     */
    private getContent() {
        if (this.viewService.isWidgetInMemory(this.widgetId)) {
            this.widget = this.viewService.getWidgetById(this.widgetId);
            if (!this.widget.enabled) {
                this.widgetIsExpired();
            } else {
                this.setMetaTags();
                this.displayState = 'visible';
                setTimeout(() => this.listenForSignUp(), 0);
            }

        } else {
            this.viewService.callWidget(this.widgetId).subscribe(
                (data: any) => {
                    if (data.error && data.error.length > 0) this.displayState = 'not-found';

                    else {
                        this.widget = this.viewService.buildWidget(data.result);
                        if (!this.widget.enabled) {
                            this.widgetIsExpired();
                        } else {
                            this.setMetaTags();
                            this.displayState = 'visible';
                            setTimeout(() => this.listenForSignUp(), 0);
                        }
                    }
                }
            )
        }
    }

    /**
     *
     */
    private initiateRegistration(): void {
        this.userService.subscribeToRegistrationToggle();
        this.userService.registrationToggle.subscribe(
            (res: any) => {
                if (res.method === 'sign-in') {
                    this.signUpVisible = false;
                    this.signInVisible = true;
                }
                if (res.method === 'sign-up') {
                    this.signInVisible = false;
                    this.signUpVisible = true;
                }
                if (res.email && res.message) {
                    setTimeout(() => {
                        const cardAction =  this.modal.getModalById('pin-modal-1').componentInstance._cardAction;
                        cardAction.errorMessage = 'You\'re already a member! Sign In or reset your password';
                        cardAction.cardActionForm.controls['email'].setValue(res.email);
                        cardAction.cardActionForm.updateValueAndValidity();
                        cardAction.ref.markForCheck();
                    }, 200);
                }
            }
        );
        this.viewService.callViewNoFilterMap(true, registrationPropertyPageList, 'registration')
            .subscribe((view: any) => {
                this.registrationData = view.result;
                this.ref.markForCheck();
            });
        this.viewService.callViewNoFilterMap(true, signinPropertyPageList, 'signin')
            .subscribe((view: any) => {
                this.signinData = view.result;
                this.ref.markForCheck();
            });
    }

    listenForSignUp() {
        this._widget.forEach(widget => {
            widget._triggerRegistrationForm.subscribe(
                (data: any) => this.updateSignUp(data)
            )
        });
    }

    updateSignUp(event: boolean) {
        this.signUpVisible = event;
        setTimeout(() => {this.listenForRegistrationComplete()}, 0);
    }
    updateSignIn(event: boolean) {
        this.signInVisible = event;
        setTimeout(() => {this.listenForRegistrationComplete()}, 0);
    }
    signOut() {
        this.authService.logout();
        window.location.reload();
    }

    listenForRegistrationComplete() {
        this._views.forEach(view => {
            if (view.view.id === registrationPropertyPageList) {
                view.markRegistrationComplete.subscribe((user: User) => {
                    // this.user = user;
                    // this.userService.setUser(this.user);
                    this.triggerCurrentApplicationStart();
                    this.triggerCurrentApplicationStart();
                    this.ref.markForCheck();
                });
                view.markUserProfileChange.subscribe((user: User) => {
                    // this.user = user;
                    // this.userService.setUser(this.user);
                    this.ref.markForCheck();
                })
            }
        })
    }

    updatePageState(value?: boolean) {
        value ? this.backButtonVisible = value : this.backButtonVisible = !this.backButtonVisible;
        if (this.backButtonVisible) this.state = 'reveal';
        this.ref.markForCheck();
    }

    toggleMenuState() {
        this.menuState = !this.menuState;
    }

    newEntryHistory(event: HistoryItem) {
        this.userService.pushHistoryItem(event);
        this.ref.markForCheck();
    }

    triggered = false;
    public triggerCurrentApplicationStart() {
        if (!this.triggered) {
            this.triggered = true;
            this._widget.forEach(widget => {
                widget._cards.forEach(card => {
                    if (card.card.type === 'Expanded') {
                        card._cardAction.submitForm(card._cardAction.cardActionForm);
                    }
                })
            });
        }
    }

    private setMetaTags() {
        this.meta.updateTag({name:'og:url', content: environment.siteUrl + this.widgetId});
        for (let attribute of this.widget.attributes) {
            if (attribute.attribute === 'Opengraph Title') this.meta.updateTag({name:'og:title', content: attribute.value});
            if (attribute.attribute === 'Opengraph Description') this.meta.updateTag({name:'og:description', content: attribute.value});
            if (attribute.attribute === 'Opengraph Image') this.meta.updateTag({name: 'og:image', content: environment.imageUrl + attribute.value});
        }
    }

    private widgetIsExpired() {
        this.displayState = 'expired';
    }

}
