import DateTimeFormat = Intl.DateTimeFormat;
import {EventEmitter} from "@angular/core";
import {cardStateChange, PinWidget} from 'ng-core-components';

export type view_state_changes = 'feed' | 'single' | 'modal' | 'expanded';

export class Property {
    constructor(
        private enabled: boolean,
        public slug: string,
        public name: string,
        public id: number,
        private dateStart?: DateTimeFormat,
        private dateClosedSoft?: DateTimeFormat,
        private dateClosedHard?: DateTimeFormat,
        private audience?: string,
    ) {}
}
export class Page {
    constructor(
        public property: number,
        public slug: string,
        public name: string,
        public id: number,
        public type: string,
    ) {}
}
export class View {
    constructor(
        public id: number,
        private enabled: boolean,
        private sortOrder: number,
        public widgets: Widget[],
        private description?: string,
        private usecase?: string,
        private customOperatorLogic?: string,
        public property?: number,
        public page?: Page,
    ) {}
}
export class Widget {
    constructor(
        public enabled: boolean,
        public cards: Card[],
        public name: string,
        public id: number,
        public approved: boolean,
        public attributes?: Attribute[],
        public dateEnd?: Date,
        public dateStart?: Date,
        public customOperatorLogic?: string,
        public state?: string,
        public stateChanges?: EventEmitter<any>,
        public propertyPageList?: number,
    ) {}
}
export class Card {
    constructor(
        public name: string,
        public sections: CardSection[],
        public id: number,
        public approved: boolean,
        public type: string,
        public actions: CardAction[],
        public attributes?: Attribute[],
        public count?: number,
        public validStartDate?: DateTimeFormat,
        public validEndDate?: DateTimeFormat,
        public state?: string,
        public stateChanges?: EventEmitter<cardStateChange>,
    ) {}
}
export class CardSection {
    constructor(
        public enabled: boolean,
        public sortOrder: number,
        public name: string,
        public id: number,
        public content: any,
        public attributes?: Attribute[],
        public type?: string,
        public size?: string,
    ) {}
}
export class CardAction {
    constructor(
        public id: number,
        public name: string,
        public type: string,
        public attributes?: Attribute[]
    ) {}
}
export class Attribute {
    constructor(
        public attribute: string,
        public value: any
    ) {}
}

export class User {
    constructor(
        public id: number,
        public email: string,
        public latitude?: number,
        public longitude?: number,
        public username?: string,
        public firstName?: string,
        public lastName?: string,
        public school?: string,
        public areaOfStudy?: string,
        public country?: string,
        public province?: string,
        public city?: string,
        public entryCount?: number,
        public entriesDoubled?: boolean
    ) {}
}

export class HistoryItem {
    constructor(
        public id: string,
        public card: Card,
        public comment: string,
        public isVerified: boolean,
        public stage: number,
        public createdAt: DateTimeFormat,
        public updatedAt: DateTimeFormat,
        public widgetId: number,
        public propertyId: number,
        public propertyPageId: number,
        public propertyPageListId: number,
        public count?: number,
        public snoozedTill?: DateTimeFormat,
    ) {}
}
