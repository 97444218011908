/**
 *
 * Auth Service only handles actions related to JWTs & Authentication for the Squarecrop API.
 * Get an access token (login), refresh my access token (refresh), remove all tokens (logout), etc.
 *
 * NOTE: Sign Up / Registration is done through cards & widgets (see: CardActionComponent)
 *
 */

import {ChangeDetectorRef, EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';

import {PinProgressSpinner} from 'ng-core-components';

export const apiUrl = '/api';

export class LoginResponse {
    constructor(
        public token: string,
        public id: string,
        public refresh_token: string,
        public roles: any[]
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private httpClient: HttpClient,
        private spinner: PinProgressSpinner) { }

    tokenRefresh = new EventEmitter<any>();

    /**
     * Take the error response from the API, convert it to
     * a message for the user, and display it.
     */
    authErrorMessage = new EventEmitter<string>();

    /**
     * User enters their username and password,
     * we encode that and send to the API for authentication.
     * @param username
     * @param password
     */
    login (username: string, password: string): Observable<LoginResponse> {

        /** Start the progress spinner */
        // this.spinner.open(null);

        /** Encode their credentials */
        const credentials = btoa(username + ':' + password);
        /** Build Headers */
        const headers = new HttpHeaders().set('Authorization', 'Basic ' + credentials);
        /** Build API Call */
        const postObservable = this.httpClient.post<LoginResponse>(apiUrl + '/tokens', {}, { headers });
        /** Build object for response */
        const subject = new ReplaySubject<LoginResponse>(1);

        /** Setup subject to handle the auth response */
        subject.subscribe((r: LoginResponse) => {
            /** Dismiss the progress spinner */
            // this.spinner.dismiss();
            /** Save the details to local storage */
            this.setAccessToken(r.token);
            this.setUserId(parseInt(r.id));
            this.setRefreshToken(r.refresh_token);
            this.setRoles(r.roles);
        }, (err) => {
            this.setAccessToken(null);
            this.setUserId(null);
            this.setRefreshToken(null);
            this.setRoles(null);
            /** Dismiss the progress spinner */
            this.spinner.dismiss();
            /** Convert the error into text to display to the user. */
            this.handleAuthenticationError(err);
        });

        /** Make the API call */
        postObservable.subscribe(subject);

        /** Return the response. */
        return subject;
    }

    /**
     * If a user's AuthToken is expired, but their refresh token is still
     * available, use that to retrieve a new token.
     */
    refresh (): Observable<LoginResponse> {

        /** Start the progress spinner */
        // this.spinner.open(null);

        /** Build the body of the call */

        const body = {
            'refresh_token': this.getRefreshToken()
        };

        /** Build the headers */
        const headers = new HttpHeaders().set('Content-Type', 'application/json');



        /** Build the API call */
        const refreshObservable = this.httpClient.post<LoginResponse>(apiUrl + '/tokens/refresh', body, { headers });

        /** Build object for response */
        const refreshSubject = new ReplaySubject<LoginResponse>(1);

        /** Setup subject to handle the response */
        refreshSubject.subscribe((r: LoginResponse) => {
            /** Dismiss the progress spinner */
            // this.spinner.dismiss();
            /** Save the details to local storage */
            this.setAccessToken(r.token);
            this.setRefreshToken(r.refresh_token);
            this.setUserId(parseInt(r.id));
            this.setRoles(r.roles);
            this.tokenRefresh.emit(true);
        }, (err) => {
            this.setAccessToken(null);
            this.setUserId(null);
            this.setRefreshToken(null);
            this.setRoles(null);
            /** Dismiss the progress spinner */
            this.spinner.dismiss();
            /** Convert the error into text to display to the user. */
            this.handleAuthenticationError(err);
        });

        /** Make the API call */
        refreshObservable.subscribe(refreshSubject);

        /** Return the response. */
        return refreshSubject;
    }

    /**
     * Logs the user out of the current session.
     * Remove variables saved in local storage, reload the page.
     */
    logout (): void {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('roles');
        window.location.reload();
    }

    /**
     * @param accessToken: string
     */
    private setAccessToken (accessToken: string): void {
        if (!accessToken) localStorage.removeItem('access_token');
        else localStorage.setItem('access_token', accessToken);
    }


    /**
     * @return accessToken: string
     */
    getAccessToken (): string {
        return localStorage.getItem('access_token');
    }

    _hasAccessToken(): boolean {
        return !!(localStorage.getItem('access_token'));
    }

    /**
     * @param id: number
     */
    private setUserId (id: number): void {
        if (!id) localStorage.removeItem('id');
        else localStorage.setItem('id', id.toString());
    }

    /**
     * @return userId: number
     */
    getUserId (): number {
        return parseInt(localStorage.getItem('id'));
    }

    _hasUserId(): boolean {
        return !!(localStorage.getItem('id'));
    }

    /**
     * @param refreshToken: string
     */
    private setRefreshToken (refreshToken: string): void {
        if (!refreshToken) localStorage.removeItem('refresh_token');
        else localStorage.setItem('refresh_token', refreshToken);
    }

    /**
     * @return refreshToken: string
     */
    getRefreshToken (): string {
        return localStorage.getItem('refresh_token');
    }

    _hasRefreshToken(): boolean {
        return !!(localStorage.getItem('refresh_token'));
    }

    /**
     * @param roles: any[]
     */
    private setRoles (roles: any[]): void {
        if (!roles || roles.length < 1) localStorage.removeItem('roles');
        else localStorage.setItem('roles', JSON.stringify(roles));
    }

    /**
     * @return roles: any[]
     */
    getRoles (): any[] {
        return JSON.parse(localStorage.getItem('roles'));
    }

    _hasRoles(): boolean {
        return !!(localStorage.getItem('roles'));
    }

    /**
     * @return isAuthenticated: boolean
     */
    _isAuthenticated (): boolean {
        return !!(this.getAccessToken() && this.getUserId());
    }

    /**
     *
     * @param err
     */
    private handleAuthenticationError (err: any): void {
        // this.setAccessToken(null);
        // this.setRefreshToken(null);

        this.authErrorMessage.emit(this.convertErrorMessage(err));
    }

    /**
     *
     * @param err
     */
    private convertErrorMessage(err: any): string {
        if (err.error) {
            switch (err.error.message) {
                case 'Bad credentials': {
                    return 'Invalid email / password. Please try again.'
                }
                default: {
                    return err.error.message;
                }
            }
        }
    }


}
