    import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { JwtInterceptor } from '@auth0/angular-jwt';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    requesting: boolean;

    constructor (
        private authService: AuthService,
        private jwtInterceptor: JwtInterceptor) {
    }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Inject
        if (this.jwtInterceptor.isWhitelistedDomain(req) && !this.jwtInterceptor.isBlacklistedRoute(req) && this.authService._hasAccessToken()) {
            return next.handle(req).pipe(
                catchError((err) => {
                    const errorResponse = err as HttpErrorResponse;
                    if ((errorResponse.status === 401 || errorResponse.status === 500)) {
                        return this.authService.refresh().pipe(mergeMap(() => {
                            return this.jwtInterceptor.intercept(req, next);
                        }));
                    }
                    return throwError(err);
                }));
        } else if (req.url === '/api/tokens/refresh') {
            return next.handle(req).pipe(
                catchError((err) => {
                    const errorResponse = err as HttpErrorResponse;
                    this.authService.logout();
                    return throwError(err);
                })
            )
        } else {
            return next.handle(req);
        }
    }
}
