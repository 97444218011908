import {
    Component,
    OnInit,
} from '@angular/core';
import {Meta} from "@angular/platform-browser";

import {PinModal, PinCard, Card, CardSection, Attribute} from 'ng-core-components';
import {environment} from "../../../environments/environment";
import {PinIconSet} from "../../shared/iconSet";
import {UserService} from "../../services/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'password-reset',
    templateUrl: './password-reset.html',
    styleUrls: ['./password-reset.scss'],
})
export class PasswordResetComponent implements OnInit {

    env = environment;

    iconSet = PinIconSet;


    menuState = false;
    backButtonVisible = false;

    resetForm: FormGroup;
    email: string;
    token: string;

    buttonLoading = false;

    errorMessage: string;

    constructor(private route: ActivatedRoute,
                private userService: UserService,
                private meta: Meta,
                public modal: PinModal) {


        /** Update Meta Tags for Opengraph */
        meta.updateTag({name: 'og:title', content: '99 Scholarships'});
        meta.updateTag({name: 'og:descripton', content: 'Money for your passions, not your grades.'});
        meta.updateTag({name: 'og:image', content: environment.siteUrl + 'assets/images/opengraph.png?1.0.1'});
        meta.updateTag({name: 'og:url', content: environment.siteUrl});
    }

    ngOnInit() {

        this.route.queryParamMap.subscribe(params => {
            if (params.has('email') && params.has('token')) {
                this.email = decodeURIComponent(params.get('email'));
                this.token = params.get('token');
            } else {

            }
        });

        this.resetForm = new FormGroup({
            email: new FormControl(this.email, [Validators.required]),
            token: new FormControl(this.token, [Validators.required]),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
        });

    }

    public submitForm(form: any) {

        const body = {
            email: form.email,
            token: form.token,
            password: form.password
        };
        this.buttonLoading = true;
        this.userService.saveNewPassword(body).subscribe(
            (data: any) => {
                if (data) {
                    this.launchSuccessModal();
                }
                this.buttonLoading = false;
            },
            (err: HttpErrorResponse) => {
                if (err.error && err.error[0]) {
                    this.errorMessage = err.error[0];
                }
                this.buttonLoading = false;
            }
        )
    }

    public matchPassword(password: string, confirmPassword: string) {
        if (confirmPassword.length > 7) {
            if (password === confirmPassword) {
                this.resetForm.controls['confirmPassword'].setErrors(null);
            } else {
                this.resetForm.controls['confirmPassword'].setErrors({'passwordsDontMatch': true})
            }
        }
        this.resetForm.updateValueAndValidity();
    }

    private handleError(err: HttpErrorResponse) {

    }

    private launchSuccessModal() {
        const sections = [
            new CardSection(
                true,
                0,
                'success_message_1',
                1,
                '<h1>Password has been changed!</h1><p>Your new password has been saved. Please login.</p>',
                null,
                'innerHtml',
                'full'
            ),
            new CardSection(
                true,
                1,
                'success_message_2',
                2,
                null,
                [
                    new Attribute(
                        'button-label',
                        'Continue to login'
                    ),
                    new Attribute(
                        'button-href',
                        '../?modal=sign-in'
                    )
                ],
                'button',
                'full'
            )
        ];

        const card = new Card(
            'success_modal',
            sections,
            0,
            true,
            'Modal',
            null,
            null
        );

        const modal = this.modal.open(PinCard);
        const instance = modal.componentInstance;
        instance.index = 0;
        instance.backButtonVisible = false;
        instance.card = card;
        instance.card.state = 'visible';
    }

}

