import {Component, OnInit} from '@angular/core';

import {AuthService} from './services/auth.service';

import {footerIcons} from "./shared/footer-icons";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {googleAnalytics} from "../assets/js/script";
import 'rxjs/add/operator/filter';
import {UserService} from "./services/user.service";
import {HistoryItem, User} from "ng-core-components";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    icons = footerIcons;

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router) {

        this.router.events.filter(event => event instanceof NavigationStart).subscribe(event => {
            const url = event['url'];
            if (url !== null && url !== undefined && url !== '' && url.indexOf('null') < 0) {
                googleAnalytics(url);
            }
        });
    }

    ngOnInit() {
        /**
         * If there's a user ID in local storage, the user already has
         * an account and has been logged in on this device. Try to refresh
         * the tokens, and return their profile and entry history details.
         */
        if (this.authService.getUserId()) this.initiateUser();
        else this.userService.subscribeToSignIn();

        this.authService.tokenRefresh.subscribe((data: any) => {
            this.initiateUser();
        });

        this.userService.subscribeToUserChanges();
    }

    signOut() {
        this.authService.logout();
        window.location.reload();
    }

    private initiateUser(): void {

        /** Get user profile details */
        if (!this.userService._hasUser()) {
            this.userService.callUser();
            this.userService.userObservable.subscribe(
                (user: User) => {}
            )
        }

        /** Get user entry history details */
        if (!this.userService._hasHistory()) {
            this.userService.callUserHistory();
            this.userService.historyObservable.subscribe(
                (history: HistoryItem[]) => {}
            )
        }

    }


}
