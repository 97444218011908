import {
    Component,
    AfterContentInit,
    OnInit,
    ElementRef,
    HostListener,
    ViewChildren,
    QueryList,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef
} from '@angular/core';
import {Meta} from "@angular/platform-browser";
import {animate, keyframes, query, stagger, state, style, transition, trigger} from '@angular/animations';

import {_cardActionOptions, CardActionEntryBody, PinView, HistoryItem, User, PinModal, Attribute, Widget} from 'ng-core-components';

import {environment} from "../../environments/environment";

import {AuthService} from "../services/auth.service";
import {ViewService} from "../services/view.service";
import {UserService} from '../services/user.service';

import {PinIconSet} from '../shared/iconSet';
import {ActivatedRoute} from "@angular/router";

const homepagePropertyPage = 15;
const registrationPropertyPage = 16;
const homepagePropertyPageList = 13;
const registrationPropertyPageList = 14;
const signinPropertyPageList = 15;
import DateTimeFormat = Intl.DateTimeFormat;

@Component({
    selector: 'cls-home',
    templateUrl: './home.html',
    styleUrls: ['./home.scss'],
    animations: [
        // Header animation on scroll
        trigger('scrollAnimation', [
            state('hide', style({
                transform: "translateY(-100%)",
            })),
            state('reveal',   style({
                transform: "translateY(0)"
            })),
            transition('* <=> *', animate('300ms ease-in')),
        ]),

        // Animate cards in
        trigger('cardAnimation', [
            // Transition from any state to any state
            transition('* => *', [
                // Initially the all cards are not visible
                query(':enter', style({ opacity: 0 }), { optional: true }),

                // Each card will appear sequentially with the delay of 300ms
                query(':enter', stagger('200ms', [
                    animate('0.6s ease-in', keyframes([
                        style({ opacity: 0, transform: 'translateY(20%) scale(0.95)', offset: 0 }),
                        // style({ opacity: .5, transform: 'translateY(10px) scale(0.95)', offset: 0.3 }),
                        style({ opacity: 1, transform: 'translateY(0) scale(1)', offset: 1 }),
                    ]))]), { optional: true }),

                // Cards will disappear sequentially with the delay of 300ms
                query(':leave', stagger('300ms', [
                    animate('500ms ease-out', keyframes([
                        style({ opacity: 1, transform: 'scale(1.1)', offset: 0 }),
                        style({ opacity: .5, transform: 'scale(.5)', offset: 0.3 }),
                        style({ opacity: 0, transform: 'scale(0)', offset: 1 }),
                    ]))]), { optional: true })
            ]),
        ]),
    ]
})
export class HomeComponent implements OnInit, AfterViewInit {

    env = environment;

    iconSet = PinIconSet;


    menuState = false;
    backButtonVisible = false;

    /**
     * Widgets & Cards
     */
    widgetCIP = false;
    widgetsInMemory: Widget[] = [];
    widgetsToPrint: Widget[] = [];
    loading = false;
    signUpVisible = false;
    signInVisible = false;

    winners: Widget[] = [];

    /**
     * Current User
     */
    user: User;
    userHistory: HistoryItem[];

    regLoading = false;

    /**
     * Animate the header based on how far you scroll down the hero.
     */
    @ViewChild('hero', {static: false}) _hero: ElementRef;
    heroHeight: number;
    state = 'hide';

    /**
     * Lazy load the cards and animate on scroll.
     */
    @ViewChild('widgetWrapper', {static: true}) _widgetWrapper: ElementRef;
    widgetHeight: number;
    widgetOffset = 0;

    registrationData: any;
    signinData: any;

    widgets: Widget[];
    fetching = false;

    @ViewChildren(PinView) _views: QueryList<PinView>;

    @ViewChild('signUp', {static: false}) _signUpView: PinView;
    @ViewChild('signIn', {static: false}) _signInView: PinView;

    params: string;

    constructor(private authService: AuthService,
                private viewService: ViewService,
                private userService: UserService,
                private ref: ChangeDetectorRef,
                private meta: Meta,
                public modal: PinModal,
                private route: ActivatedRoute) {

        /** Update Meta Tags for Opengraph */
        meta.updateTag({name:'og:title', content:'99 Scholarships'});
        meta.updateTag({name:'og:descripton', content:'Money for your passions, not your grades.'});
        meta.updateTag({name:'og:image', content:environment.siteUrl+'assets/images/opengraph.png?1.0.1'});
        meta.updateTag({name:'og:url', content: environment.siteUrl});
    }

    ngOnInit() {

        if (this.userService.getUser()) {
            this.user = this.userService.getUser();
        }
        this.userService.userObservable.subscribe(
            (user: User) => {
                this.user = user;
            }
        );

        /**
         * Call the widgets to display on the homepage.
         */
        this.initiateView();

        this.viewService.subscribeToWidgetCompletes();

        this.authService.tokenRefresh.subscribe((data: any) => {
            // this.initiateView();
            this.ref.markForCheck();
        });

    }

    /**
     *
     */
    private initiateView(): void {
        this.widgetsInMemory = [];
        /** */
        this.loading = true;
        this.widgetCIP = true;
        /** */
        this.viewService.callViewFromDatabase(!this.authService._isAuthenticated(), !this.authService._isAuthenticated() ? homepagePropertyPageList : homepagePropertyPage, 'feed')
            .subscribe((data: any) => {
                this.widgetCIP = false;
                // if (data) this.widgetsInMemory = data;
                /** */
                if (data) this.prepWidgets(data);
            });

        /** */
        this.initiateRegistration();

        this.getWinners();

        this.route.queryParamMap.subscribe(params => {
            if (params.has('modal')) {
                this.params = params.get('modal');
            }
        });
    }

    /**
     *
     */

    /**
     *
     */
    private initiateRegistration(): void {
        this.userService.subscribeToRegistrationToggle();
        this.userService.registrationToggle.subscribe(
            (res: any) => {
                if (res.method === 'sign-in') {
                    this.signUpVisible = false;
                    this.signInVisible = true;
                }
                if (res.method === 'sign-up') {
                    this.signInVisible = false;
                    this.signUpVisible = true;
                }
                if (res.email && res.message) {
                    setTimeout(() => {
                        const cardAction =  this.modal.getModalById('pin-modal-1').componentInstance._cardAction;
                        cardAction.errorMessage = 'You\'re already a member! Sign In or reset your password';
                        cardAction.cardActionForm.controls['email'].setValue(res.email);
                        cardAction.cardActionForm.updateValueAndValidity();
                        cardAction.ref.markForCheck();
                    }, 200);
                }
            }
        );
        this.viewService.callViewNoFilterMap(true, registrationPropertyPageList, 'registration')
            .subscribe((view: any) => {
                if (view) {
                    this.registrationData = view.result;
                    if (this.params === 'sign-up') this.signUpVisible = true;
                    this.ref.markForCheck();
                }
            });
        this.viewService.callViewNoFilterMap(true, signinPropertyPageList, 'signin')
            .subscribe((view: any) => {
                if (view) {
                    this.signinData = view.result;
                    if (this.params === 'sign-in') this.signInVisible = true;
                    this.ref.markForCheck();
                }
            });
    }

    ngAfterViewInit() {
        this.heroHeight = this._hero.nativeElement.offsetHeight;
    }

    prepWidgets(widgets: Widget[]) {
        this.widgetsInMemory = widgets;
        let i = 0;
        while (i < 3) {
            if (this.widgetsInMemory[i] && this.widgetsInMemory[i].cards[0].type === 'Preview') {
                this.widgetsToPrint.push(this.widgetsInMemory[i]);
            }
            this.widgetOffset++;
            i++;
        }
        this.loading = false;
        setTimeout(() => {
            this.widgetHeight = this._widgetWrapper.nativeElement.offsetHeight + this._widgetWrapper.nativeElement.offsetTop;
        }, 0);
        this.ref.markForCheck();

    }

    getMoreWidgets() {
        if (this.widgetsInMemory.length > this.widgetsToPrint.length) {
            this.fetching = true;
            let j = this.widgetOffset;
            let k = j + (this.widgetsInMemory.length - this.widgetsToPrint.length > 3 ? 3 : this.widgetsInMemory.length - this.widgetsToPrint.length);
            while (j < k) {
                if (this.widgetsInMemory[j] && this.widgetsInMemory[j].cards[0].type === 'Preview') {
                    this.widgetsToPrint.push(this.widgetsInMemory[j]);
                }
                this.widgetOffset++;
                j++;
            }
            this.fetching = false;
        }
        setTimeout(() => {
            this.widgetHeight = this._widgetWrapper.nativeElement.offsetHeight + this._widgetWrapper.nativeElement.offsetTop;
        }, 0);
        this.ref.markForCheck();
    }

    updateSignUp(event: boolean) {
        this.signUpVisible = event;
        setTimeout(() => {this.listenForRegistrationComplete()}, 0);
    }

    updateSignIn(event: boolean) {
        this.signInVisible = event;
        setTimeout(() => {this.listenForRegistrationComplete()}, 0);
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset;

        if (this.backButtonVisible) {
            this.state = 'reveal';
        } else {
            if (scrollPosition < this.heroHeight - 72) {
                this.state = 'hide';
            } else {
                this.state = 'reveal';
            }
        }
        if (scrollPosition > this.widgetHeight - (window.innerHeight - 50) ){
            if (!this.fetching) this.getMoreWidgets();
        }


    }

    signOut() {
        this.authService.logout();
        window.location.reload();
    }

    listenForRegistrationComplete() {
        this._views.forEach(view => {
            if (view.view.id === registrationPropertyPageList) {
                view.markRegistrationComplete.subscribe((user: User) => {
                    this.user = user;
                    // this.userService.setUser(this.user);
                    this.triggerNextCardAfterRegistration();
                    this.ref.markForCheck();
                });
                view.markUserProfileChange.subscribe((user: User) => {
                    this.user = user;
                    // this.userService.setUser(this.user);
                    this.ref.markForCheck();
                });
            }
        })
    }

    triggerNextCardAfterRegistration() {
        this._views.forEach(view => {
            if (view.view.id === homepagePropertyPageList) {
                view.triggerCurrentApplicationStart();
            }
        })
    }

    updatePageState(value?: boolean) {
        value ? this.backButtonVisible = value : this.backButtonVisible = !this.backButtonVisible;

        if (this.backButtonVisible) this.state = 'reveal';
        else {
            const scrollPosition = window.pageYOffset;
            if (scrollPosition < 56) {
                this.state = 'hide';
            } else {
                this.state = 'reveal';
            }
        }
        this.ref.markForCheck();
    }

    toggleMenuState() {
        this.menuState = !this.menuState;
    }
    newEntryHistory(event: HistoryItem) {
        this.userService.pushHistoryItem(event);
        this.ref.markForCheck();
    }

    finishRegistrationTriggerWidget(event: any) {
    }

    buildCardActionParameters(widgetIndex: number): CardActionEntryBody {
        return new CardActionEntryBody(
            this.authService.getUserId(),
            this.widgetsToPrint[widgetIndex].cards[0].id,
            false,
            0,
            this.widgetsToPrint[widgetIndex].id,
            13
        );
    }

    convertActionAttributesToActionOptions(attributes: Attribute[]): _cardActionOptions {
        const options = new _cardActionOptions();
        for (let attribute of attributes) {
            if (options.hasOwnProperty(attribute.attribute)) {
                if (attribute.attribute === 'options') {
                    options[attribute.attribute] = JSON.parse(attribute.value);
                } else {
                    options[attribute.attribute] = attribute.value;
                }
            }
        }
        return options;
    }

    smoothScroll(el: HTMLElement): void {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    isWidgetVisible(widget: Widget): boolean {
        const today = new Date();
        const dateStart = widget.dateStart ? widget.dateStart : null;
        const dateEnd = widget.dateEnd ? widget.dateEnd : null;
        if (widget.enabled
            && widget.cards[0].type === 'Preview'
            && ((dateStart && dateStart < today) || widget.dateStart === null)
            && ((dateEnd && dateEnd > today) || widget.dateEnd === null)) {
                return true;
        }
        return false;
    }

    hasTags(widget: Widget): string | null {
        if (this.tagClosingSoon(widget)) return 'ends_soon';
        else if (this.tagNew(widget)) return 'new';
        else return null;
    }

    private tagClosingSoon(widget: Widget): boolean {
        const oneWeekFromNow = this.viewService.oneWeekFromNow;
        if (widget.dateEnd && widget.dateEnd < oneWeekFromNow) return true;
        return false;
    }

    private tagNew(widget: Widget): boolean {
        const oneWeekAgo = this.viewService.oneWeekAgo;
        if (widget.dateStart && widget.dateStart < oneWeekAgo) return true;
        return false;
    }

    getWinners() {
        this.viewService.callViewFromDatabase(true, 27, 'feed')
            .subscribe((data: any) => {
                this.winners = data;
            });
    }


}

