import {EventEmitter} from "@angular/core";
import {cardStateChange} from 'ng-core-components';

export class Widget {
  constructor(
    public id: number,
    public name: string,
    public approved: boolean,
    public enabled: boolean,
    public attributes: Attribute[] = [],
    public cards: Card[] = [],
    public tags: any[] = [],
    public dateStart?: Date,
    public dateEnd?: Date,
  ) {}
}

export class Card {
  constructor(
      public name: string,
      public sections: CardSection[],
      public id: number,
      public approved: boolean,
      public type: string,
      public actions: CardAction[],
      public attributes?: Attribute[],
      public count?: number,
      public validStartDate?: Date,
      public validEndDate?: Date,
      public state?: string,
      public stateChanges?: EventEmitter<cardStateChange>,
  ) {}
}

export class CardSection {
  constructor(
      public enabled: boolean,
      public sortOrder: number,
      public name: string,
      public id: number,
      public content: any,
      public attributes?: Attribute[],
      public type?: string,
      public size?: string,
  ) {}
}

export class CardAction {
  constructor(
    public id: number,
    public name: string,
    public type: string,
    public attributes: Attribute[] = []
  ) {}
}

export class Attribute {
  constructor(
    public attribute: string,
    public value: any
  ) {}
}



export function buildWidget(widget: any): Widget {
  const attributes = widget.attributes ? buildAttributes(widget.attributes) : [];

  return new Widget(
    widget.id,
    widget.name,
    widget.approved,
    widget.enabled,
    attributes,
    widget.widgetsCards && widget.widgetsCards.length > 0 ? buildCards(widget.widgetsCards) : [],
    attributes && getAttributeByName(attributes, 'Tags') ? JSON.parse(getAttributeByName(attributes, 'Tags')) : [],
    widget.dateStart ? new Date(widget.dateStart.date.replace(/ /g,"T")) : null,
    widget.dateEnd  ? new Date(widget.dateEnd.date.replace(/ /g,"T")) : null
  );
}

export function buildWidgets(widgets: any): Widget[] {
  return widgets.filter(widget => filterWidgets(widget.widget)).map(widget => buildWidget(widget.widget));
}

export function filterWidgets(widget: any): boolean {
  const today = new Date();
  const approved = widget.approved;
  const enabled = widget.enabled;
  const startDate = widget.dateStart ? new Date(widget.dateStart.date.replace(/ /g,"T")) : null;
  const endDate = widget.dateEnd ? new Date(widget.dateEnd.date.replace(/ /g,"T")) : null;

  return approved && enabled && (startDate ? startDate < today : true) && (endDate ? endDate > today : true);
}

export function buildCard(card: any): Card {
  let attributes = buildAttributes(card.attributes);
  return new Card(
    card.name,
    card.cardSections ? buildCardSections(card.cardSections) : [],
    card.id,
    card.approved,
    getAttributeByName(attributes, 'Type'),
    card.cardActions ? buildCardActions(card.cardActions) : [],
    attributes,
    card.count,
    card.validStartDate ? new Date(card.validStartDate.date.replace(/ /g,"T")) : null,
    card.validEndDate ? new Date(card.validEndDate.date.replace(/ /g,"T")) : null,
    'hidden',
    new EventEmitter<cardStateChange>()
  );
}

export function buildCards(cards: any): Card[] {
  return cards.filter(card => card.card ? filterCards(card.card) : false).map(card => buildCard(card.card));
}

export function filterCards(card: any): boolean {
  const today = new Date();
  const approved = card.approved;
  const startDate = card.validStartDate ? new Date(card.validStartDate.date.replace(/ /g,"T")) : null;
  const endDate = card.validEndDate ? new Date(card.validEndDate.date.replace(/ /g,"T")) : null;
  return approved && (startDate ? startDate < today : true) && (endDate ? endDate > today : true);
}



export function buildCardSection(cardSection: any): CardSection {
  let attributes = buildAttributes(cardSection.attributes);
  return new CardSection(
      cardSection.enabled,
      cardSection.sortOrder,
      cardSection.name,
      cardSection.id,
      getAttributeByName(attributes, 'content'),
      attributes,
      cardSection.type,
      cardSection.size,
  );
}

export function buildCardSections(cardSections: any): CardSection[] {
  return cardSections.filter(section => section.enabled).map(section => buildCardSection(section));
}



export function buildCardAction(cardAction: any): CardAction {
  return new CardAction(
    cardAction.id,
    cardAction.name,
    cardAction.type,
    buildAttributes(cardAction.attributes)
  );
}

export function buildCardActions(cardActions: any): CardAction[] {
  return cardActions.map(action => buildCardAction(action));
}


export function buildAttributes(attributes: any): Attribute[] {
  const list: Attribute[] = [];
  for (const key in attributes) {
    if (attributes.hasOwnProperty(key)) {
      const item = attributes[key];
      list.push(new Attribute(key.toString(), item));
    }
  }
  return list;
}

export function getAttributeByName(attributes: Attribute[], name: string): any {
  let value = null;
  attributes.forEach(attribute => {
    if (attribute.attribute === name) { value = attribute.value; }
  });
  return value;
}
