/**
 *
 *
 *
 *
 */

import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {catchError, filter, map, tap} from "rxjs/operators";

import {cardStateChange, viewStateChange, ApiService} from "ng-core-components";

import {apiUrl, AuthService} from './auth.service';

import {Attribute, Card, CardAction, CardSection, View, Widget} from "../models/view";
import {buildWidgets} from '../models/widget';

export type view_type = 'feed' | 'registration' | 'signin';

export class ViewData {
    constructor(
        public view: View,
        public type: view_type) {
    }
}

@Injectable({
    providedIn: 'root'
})
export class ViewService {

    /**  */
    private viewData: ViewData[] = [];

    /**  */
    private widgetIndices: number[] = [];

    public oneWeekFromNow: Date;
    public oneWeekAgo: Date;

    constructor(private http: HttpClient,
                private apiService: ApiService,
                private authService: AuthService) {
        const today = new Date();
        today.setDate(today.getDate() + 7);
        this.oneWeekFromNow = today;

        const alt = new Date();
        alt.setDate(alt.getDate() - 7);
        this.oneWeekAgo = alt;
    }

    /**
     *
     * @param isPublic: boolean
     * @param id: number
     */
    callViewFromDatabase(isPublic: boolean, id: number, type: view_type): Observable<any> {
        let url = isPublic ? apiUrl + '/oPropertyPageList/' + id : apiUrl + '/PropertyPage/' + id + '/List';
        return this.http.get<any>(url)
            .pipe(
                map(res => buildWidgets(res.result.propertyPageListWidgets)),
                // tap((data: any) => {
                //     this.addViewData(new ViewData(this.buildView(data.result), type));
                // }),
                catchError(this.handleError<View>(`getView`))
            );
    }

    callViewNoFilterMap(isPublic: boolean, id: number, type: view_type): Observable<any> {
        let url = isPublic ? apiUrl + '/oPropertyPageList/' + id : apiUrl + '/PropertyPage/' + id + '/List';
        return this.http.get<any>(url)
            .pipe(
                // map(res => buildWidgets(res.result.propertyPageListWidgets)),
                // tap((data: any) => {
                //     this.addViewData(new ViewData(this.buildView(data.result), type));
                // }),
                catchError(this.handleError<View>(`getView`))
            );

    }

    /**
     * Add View Data
     *
     * @param view: View
     */
    addViewData(data: ViewData): void {

        /** If the view already exists, swap in out. */
        if (this.hasViewData(data) !== null) {
            let index = this.hasViewData(data);
            this.viewData.splice(index, 1, data);

        /** If not, push to the end of the array. */
        } else {
            this.viewData.push(data);
        }

        this.updateWidgetIndices();

    }

    private hasViewData(data: ViewData): number | null {
        for (let [i,view] of this.viewData.entries()) {
            if (data.view.id === view.view.id) {
                return i;
            }
        }
        return null;
    }

    /**
     *
     * @param type
     */
    public getViewByType(type: string): View {
        for (let view of this.viewData) {
            if (view.type === type) return view.view;
        }
        return;
    }

    /**
     *
     */
    getWidgetPreviews(): Widget[] {
        if (this.getViewByType('feed')) {
            return this.getViewByType('feed').widgets;
        }
    }

    /**
     *
     */
    private updateWidgetIndices(): void {

        /** Empty it out first. */
        this.widgetIndices = [];

        /** Then, add each id to the index. */
        for (let view of this.viewData) {
            for (let widget of view.view.widgets) {
                this.widgetIndices.push(widget.id);
            }
        }
    }


    /**
     *
     */
    private getWidgetIndices(): number[] {
        return this.widgetIndices;
    }


    /**
     *
     * @param widgetId
     */
    public isWidgetInMemory(widgetId: number): boolean {
        if (this.getWidgetIndices().indexOf(widgetId) > -1) return true;
        return false;
    }


    /**
     * Get Widget by ID
     *
     * @param widgetId: number
     * @param viewId: number
     * @return Widget
     */
    public getWidgetById(widgetId: number): Widget {

        for (let view of this.viewData) {

            for (let widget of view.view.widgets) {

                if (widget.id === widgetId) return widget;

            }
        }

        return;
    }


    /**
     *
     * @param id
     */
    public callWidget(id: number): Observable<Widget> {

        return this.http.get<Widget>(apiUrl + '/Widget/' + id)
            .pipe(
                tap(() => {}),
                catchError(this.handleError<Widget>('getWidget'))
            );
    }


    public subscribeToWidgetCompletes() {
        this.apiService.widgetComplete.subscribe(
            (id: number) => {
                if (this.isWidgetInMemory(id)) {
                    // remove from viewData
                    for (let view of this.viewData) {
                        if (view.type === 'feed') {
                            view.view.widgets.splice(this.getWidgetIndexById(id), 1);
                        }
                    }
                    // remove from widgetIndicies
                    this.widgetIndices.splice(this.getWidgetIndices().indexOf(id), 1);
                }
            }
        );
    }

    private getWidgetIndexById(id: number) {
        for (let view of this.viewData) {
            for (let widget of view.view.widgets) {
                if (widget.id === id) return view.view.widgets.indexOf(widget);
            }
        }
    }



    /****************************************************
     *
     * Helper Functions
     *
     ****************************************************/


    /**
     * Build View
     *
     * @param data
     * @return View
     */
    buildView(data: any): View {
        return new View(
            data.id,
            data.enabled,
            data.sortOrder,
            this.buildWidgets(data.propertyPageListWidgets),
            data.description,
            data.usecase,
            data.customOperatorLogic,
            data.propertyPage.property.id,
            data.propertyPage.id,
        );
    }
    parseDate(date) {
        const a = date.split(/[^0-9]/);
        return new Date (a[0],a[1]-1,a[2],a[3],a[4],a[5] );
    }

    /**
     * Build Widgets
     *
     * @param data
     * @return Widget[]
     */
    buildWidgets(data: any): Widget[] {
        const widgets: Widget[] = [];
        if (data) {
            for (let widget of data) {
                let w = widget.widget;
                let attributes: Attribute[] = [];
                if (widget.widget.attributes) {
                    for (let key in widget.widget.attributes) {
                        let item = widget.widget.attributes[key];
                        attributes.push(new Attribute(key.toString(), item));
                    }
                }
                widgets.push(new Widget(
                    w.enabled,
                    this.buildCards(w.widgetsCards),
                    w.name,
                    w.id,
                    w.approved,
                    attributes,
                    this.parseDate(w.dateEnd.date),
                    this.parseDate(w.dateStart.date),
                    w.customOperatorLogic,
                    'visible',
                    new EventEmitter<viewStateChange>()
                ))
            }
        }
        return widgets;
    }


    /**
     *
     * @param data
     */
    buildWidget(data: any): Widget {
        let w = data;
        let attributes: Attribute[] = [];
        if (data.attributes) {
            for (let key in data.attributes) {
                let item = data.attributes[key];
                attributes.push(new Attribute(key.toString(), item));
            }
        }
        return new Widget(
            w.enabled,
            this.buildCards(w.widgetsCards),
            w.name,
            w.id,
            w.approved,
            attributes,
            w.dateEnd.date,
            w.dateStart.date,
            w.customOperatorLogic,
            'visible',
            new EventEmitter<viewStateChange>()
        );
    }


    /**
     * Build Cards
     *
     * @param data
     * @return Card[]
     */
    buildCards(data: any): Card[] {
        let cards: Card[] = [];
        if (data) {
            for (let card of data) {
                if (card.card) {
                    let attributes: Attribute[] = [];
                    let type = '';
                    if (card.card.attributes) {
                        for (let key in card.card.attributes) {
                            let item = card.card.attributes[key];
                            attributes.push(new Attribute(key.toString(), item));
                            if (key.toString() === 'Type') type = item;
                        }
                    }
                    cards.push(new Card(
                        card.card.name,
                        this.buildCardSections(card.card.cardSections),
                        card.card.id,
                        card.card.approved,
                        type,
                        this.buildCardActions(card.card.cardActions),
                        attributes,
                        card.card.count,
                        card.card.validStartDate,
                        card.card.validEndDate,
                        'hidden',
                        new EventEmitter<cardStateChange>()
                    ));
                }
            }
        }
        return cards;
    }

    /**
     *
     * @param card
     */
    buildCard(card: any): any {
        if (!card) {
            return
        }
        let attributes: Attribute[] = [];
        let type = '';
        for (let key in card.attributes) {
            let item = card.attributes[key];
            attributes.push(new Attribute(key.toString(), item));
            if (key.toString() === 'Type') type = item;
        }
        return new Card(
            card.name,
            this.buildCardSections(card.cardSections),
            card.id,
            card.approved,
            type,
            this.buildCardActions(card.cardActions),
            attributes,
            card.count,
            card.validStartDate,
            card.validEndDate,
            'hidden',
            new EventEmitter<cardStateChange>()
        );
    }

    /**
     * Build Card Sections
     *
     * @param data
     * @return CardSection[]
     */
    buildCardSections(data: any): CardSection[] {
        let sections: CardSection[] = [];
        if (data) {
            for (let section of data) {
                let attributes: Attribute[] = [];
                let content = '';
                if (section && section.attributes) {
                    for (let key in section.attributes) {
                        let item = section.attributes[key];
                        attributes.push(new Attribute(key.toString(), item));
                        if (key.toString() === 'content') content = item;
                    }
                }
                sections.push(new CardSection(
                    section.enabled,
                    section.sortOrder,
                    section.name,
                    section.id,
                    content,
                    attributes,
                    section.type,
                    section.size,
                ));
            }
        }
        return sections;
    }

    /**
     * Build Card Actions
     *
     * @param data
     * @return CardAction[]
     */
    buildCardActions(data: any): CardAction[] {
        let actions: CardAction[] = [];
        if (data) {
            for (let action of data) {
                let attributes: Attribute[] = [];
                if (action && action.attributes) {
                    for (let key in action.attributes) {
                        let item = action.attributes[key];
                        attributes.push(new Attribute(key.toString(), item));
                    }
                }
                actions.push(new CardAction(
                    action.id,
                    action.name,
                    action.type,
                    attributes,
                ));
            }
            return actions;
        }
    }

    /**
     *
     * @param operation
     * @param result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


    getWinners(): Observable<any> {
        return this.http.get<any>(apiUrl + '/oPropertyPageList/27')
            .pipe(
                map(res => buildWidgets(res.result.propertyPageListWidgets)),
                catchError(this.handleError<View>(`getWinners`))
            )
    }


}
