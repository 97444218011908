/**
 *
 * User service contains a live record of the currently logged in user.
 * This includes their profile details, entry history, etc.
 *
 */

import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs/index';
import {catchError, tap} from 'rxjs/internal/operators';

import {ApiService, PinProgressSpinner, HistoryItem, User} from 'ng-core-components';

import {AuthService} from './auth.service';
import {ViewService} from "./view.service";

import {apiUrl} from './auth.service';
import {Attribute} from "../models/view";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private viewService: ViewService,
        private apiService: ApiService,
        private spinner: PinProgressSpinner,
    ) {
    }

    /**
     * Currently logged in user.
     * Contains all of their profile details.
     */
    user: User;

    /**
     * Event Emitter that other components can subscribe to.
     * This always gives them live data of the current user
     * to keep data in-sync and watch for changes.
     */
    userObservable = new EventEmitter<User>();

    /**
     * Array of all the card action entries the user has completed.
     */
    history: HistoryItem[] = [];

    /**
     * Event Emitter that other components can subscribe to.
     * This always gives them live data of the current user
     * history to keep data in-sync and watch for changes.
     */
    historyObservable = new EventEmitter<HistoryItem[]>();


    /****************************************************
     *
     * User Profile Details
     *
     ****************************************************/

    /**
     *
     */
    _hasUser(): boolean {
        return !!(this.user);
    }

    /**
     *
     */
    setUser(user: User, sendToLibrary?: boolean): void {
        /** Either save the user, or set to null. */
        if (user) this.user = user;
        else this.user = null;
        /** Emit to any components watching for user changes. */
        this.userObservable.emit(this.user);
        if (sendToLibrary) this.apiService.setUser(this.user, true);
    }

    getUser(): User {
        return this.user;
    }

    subscribeToUserChanges(): void {
        this.apiService.userProfileChange.subscribe(
            (user: User) => {
                this.setUser(user, false);
            }
        )
    }

    /** User Call in Progress */
    private userCIP = false;

    /**
     * Get all the profile details for the currently authenticated user.
     */
    private callUserFromDatabase(): Observable<User> {
        /** Start the progress spinner & don't let another call start until this is done. */
        this.userCIP = true;
        this.spinner.open(null);
        return this.http.get<User>(apiUrl + '/User/' + this.authService.getUserId())
            .pipe(
                tap((data: any) => {
                    if (data) {
                        this.setUser(this.buildUser(data.result));
                        /** Close the spinner and reset call in progress */
                        this.userCIP = false;
                        this.spinner.dismiss();
                    }
                }),
                catchError(this.handleError<User>(`getUser`))
            );
    }

    /**
     *
     */
    callUser(): void {

        this.callUserFromDatabase().subscribe(
            (data: any) => {
                if (data) this.setUser(this.buildUser(data.result), true);
            }
        )
    }

    registrationToggle = new EventEmitter<string>();

    userSignIn = new EventEmitter<any>();

    public subscribeToRegistrationToggle() {
        this.apiService.registrationToggle.subscribe(
            (method: string) => {
                this.registrationToggle.emit(method);
            }
        )
    }

    public subscribeToSignIn() {
        this.apiService.userSignIn.subscribe(
            (data: any) => {
                this.userSignIn.emit(data);
                if (data) {
                    this.callUser();
                }

            }
        );
    }

    public saveNewPassword(body: any): Observable<any> {
        return this.http.post<any>(apiUrl + '/PasswordReset', body)
            .pipe(
                tap(() => {})
            );
    }


    /****************************************************
     *
     * User Entry History
     *
     ****************************************************/

    /**
     *
     */
    _hasHistory(): boolean {
        return !!(this.history.length > 0);
    }

    /**
     *
     * @param history
     */
    setHistory(history: HistoryItem[]): void {
        if (history) this.history = history;
        else this.history = [];

        this.historyObservable.emit(this.history);

        this.apiService.setEntryHistory(this.history);
    }

    getHistory(): HistoryItem[] {
        return this.history;
    }

    /**
     *
     * @param item
     */
    pushHistoryItem(item: HistoryItem): void {
        this.history.push(item);
    }

    /**
     *
     * @param item
     */
    deleteHistoryItem(item: HistoryItem): void {
        this.history.splice(this.history.indexOf(item), 1);
    }

    /** History Call in Progress */
    private historyCIP = false;

    /**
     * Get all completed card action entries for this user & property.
     */
    private callUserHistoryFromDatabase(): Observable<HistoryItem[]> {
        /** Start the progress spinner & don't let another call start until this is done. */
        this.historyCIP = true;
        this.spinner.open(null);

        const headers = new HttpHeaders().set('Cache-Control-API', 'no-cache');

        return this.http.get<HistoryItem[]>(apiUrl + '/CardActionEntry', {headers})
            .pipe(
                tap(() => {
                    /** Close the spinner and reset call in progress */
                    this.historyCIP = false;
                    this.spinner.dismiss();
                }),
                catchError(this.handleError<HistoryItem[]>(`getHistoryItems`))
            )
    }

    /**
     *
     */
    callUserHistory(): void {
        if (!this.historyCIP) {
            this.callUserHistoryFromDatabase().subscribe(
                (data: any) => {
                    if (data) {
                        this.setHistory(this.buildHistory(data.result));
                        this.apiService.entryHistoryChange.emit(this.history);
                    }
                }
            )
        }
    }



    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            if (error.status === 401) {
                this.authService.refresh();
            }

            // TODO: better job of transforming error for user consumption
            // this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }



    /****************************************************
     *
     * Helper Functions
     *
     ****************************************************/


    buildUser(data: any): User {
        let attributes: Attribute[] = [];
        if (data.attributes) {
            for (let key in data.attributes) {
                let item = data.attributes[key];
                attributes.push(new Attribute(key.toString(), item));
            }
        }
        return new User(
            data.id,
            data.email,
            data.latitude,
            data.longitude,
            data.username,
            data.firstName,
            data.lastName,
            data.school,
            data.schoolAccepted,
            data.areaOfStudy,
            data.country,
            data.province,
            data.city,
            null,
            null,
            attributes
        );
    }

    buildHistory(data: any): HistoryItem[] {
        const historyItems: HistoryItem[] = [];
        let entryCount = 0;
        for (let item of data) {
            historyItems.push(new HistoryItem(
                item.id,
                this.viewService.buildCard(item.card),
                item.comment,
                item.isVerified,
                item.stage,
                item.createdAt,
                item.updatedAt,
                item.widget.id,
                item.property ? item.property.id : null,
                item.propertyPage ? item.propertyPage.id : null,
                item.propertyPageList ? item.propertyPageList.id : null,
                item.count,
                item.snoozedTill,
            ));
            entryCount += item.count;
        }
        return historyItems;
    }

}
