export const environment = {
  name: 'stage',
  production: true,
  imageUrl: 'https://be.stage.squarecropstudios.com/uploads/',
  siteUrl: 'https://staging.99scholarships.ca/',
  google_analytics_code: 'UA-140740037-1',
  defaultPropertyPageList: 13,
  archivedPropertyPageList: 25,
  aws: {
    identityPoolId: 'ca-central-1:4e2ec83c-cb64-4664-ada6-ce97ce818266',
    region: 'ca-central-1',
    bucketName: '99-scholarships-admin-stage',
    publicUrl: 'https://99-scholarship-submissions-prod.s3.ca-central-1.amazonaws.com'
  },
  api: {
    propertyId: 3,
    homepage: {
      id: 15,
      listId: 13
    },
    winnersPage: {
      id: 19,
      listId: 27
    },
  }
};
