import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {DatePipe} from '@angular/common';

import {JwtModule, JwtInterceptor, JWT_OPTIONS} from '@auth0/angular-jwt';
import {RefreshTokenInterceptor} from './services/refresh-token-interceptor';
import {
    PinCommonModule,
    PinHeaderModule,
    PinFooterModule,
    PinViewModule,
    PinCardModule,
    PinDesktopNavigationModule,
    PinHistoryModule,
    PinButtonModule,
    PinSideMenuModule,
    PinProgressSpinnerModule,
    PinToastModule,
    PinFormFieldModule,
    PinInputModule,
} from 'ng-core-components';

import {AuthService} from './services/auth.service';
import {ViewService} from "./services/view.service";
import {UserService} from "./services/user.service";

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home';
import {PinWidgetShow} from "./home/show/widget-show";

import {SafeHtmlPipe} from './shared/pipes/safe-html.pipe';
import {GroupByPipe} from './shared/pipes/group-by-pipe';
import {PasswordResetComponent} from "./profile/password-reset/password-reset";
import {AdminComponent} from "./admin/admin.component";
import {AdminWidgetCreate} from "./admin/widget/create/widget-create";
import {AdminWidgetEdit} from "./admin/widget/edit/widget-edit";
import {AdminWidgetList} from "./admin/widget/list/widget-list";

import {AdminSignIn} from "./admin/common/sign-in/sign-in";
import {AuthGuard} from "./admin/services/auth.guard";
import {AdminWidgetEntries} from "./admin/widget/entries/widget-entries";
import {AdminUserShow} from "./admin/users/show/user-show";
import {AdminWidgetEntryShow} from "./admin/widget/entry-show/widget-entry-show";
import {AdminWidgetCount} from "./admin/widget/count/widget-count";
import {AdminNotFound} from "./admin/common/not-found/not-found";
import {AdminModule} from "./admin/admin.module";

export function tokenGetter () {
    if (!localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token');
    }
    return localStorage.getItem('access_token');
}

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'password-reset', component: PasswordResetComponent},
    {path: ':id', component: PinWidgetShow},
    {path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    }
];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['v2.local'],
                blacklistedRoutes: ['/api/tokens', '/api/tokens/refresh', '/register', '/api/PasswordReset', '/api/PasswordReset/Email'],
            }
        }),
        PinCommonModule,
        PinViewModule,
        PinCardModule,
        PinHeaderModule,
        PinFooterModule,
        PinDesktopNavigationModule,
        PinSideMenuModule,
        PinHistoryModule,
        PinButtonModule,
        PinProgressSpinnerModule,
        PinToastModule,
        PinFormFieldModule,
        PinInputModule,
    ],
    declarations: [
        SafeHtmlPipe,
        GroupByPipe,
        AppComponent,
        HomeComponent,
        PinWidgetShow,
        PasswordResetComponent,
    ],
    providers: [
        AuthService,
        ViewService,
        UserService,
        JwtInterceptor, // Providing JwtInterceptor allow to inject JwtInterceptor manually into RefreshTokenInterceptor
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true
        },
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
