export const footerIcons = [
    {
        href: 'https://www.facebook.com/StudentLifeNetwork/',
        name: 'facebook'
    },
    {
        href: 'https://twitter.com/StudentLifeNet',
        name: 'twitter'
    },
    {
        href: 'https://ca.linkedin.com/company/student-life-network',
        name: 'linkedin'
    },
    {
        href: 'https://www.instagram.com/studentlifenetwork',
        name: 'instagram'
    }
];